<template>
  <v-row class="listing-image-block">
    <CollectionsSearchItem
      v-for="item in items"
      :id="item.id"
      :key="item.id"
      v-intersect.once="onIntersect"
      :is-show-action-icon="isShowActionIcon"
      :data-test="`${testValue}_block`"
      :test-value="testValue"
      :item="item"
      :criteria="criteria"
      class="items main-items"
      @click-action-menu="$emit('click-action-menu', $event)"
      @click.native="$emit('handleItemClick', item)" />
    <template v-if="isLoadingItems">
      <div class="collection-search-item items main-items col">
        <ListingItemSkeleton />
      </div>
      <div class="collection-search-item items main-items col">
        <ListingItemSkeleton />
      </div>
      <div class="collection-search-item items main-items col d-none d-sm-block">
        <ListingItemSkeleton />
      </div>
      <div class="collection-search-item items main-items col d-md-block">
        <ListingItemSkeleton />
      </div>
    </template>
  </v-row>
</template>
<script>
import CollectionsSearchItem from './CollectionsSearchItem';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
import ListingItemSkeleton from '@/components/App/AppSkeleton/Collections/CollectionListingItemSkeleton';
export default {
  name: 'CollectionsSearchCollapsedRows',
  components: {
    CollectionsSearchItem,
    ListingItemSkeleton,
  },
  mixins: [ToggleViewCondition],
  props: {
    criteria: {
      type: String,
      default: '',
    },
    isShowActionIcon: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    testValue: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    isLoadingItems: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
  },
  methods: {
    onIntersect(entries, observer, isIntersecting) {
      this.$emit('onIntersect', {
        entries, observer, isIntersecting,
      });
    },
  },
};
</script>
<style lang="scss">
</style>
