<template>
  <v-col
    class="collection-search-item items main-items pointer">
    <component
      :is="createAnchorForCollections && criteria !== 'products' ? 'router-link' : 'div'"
      :to="computedRoute">
      <ListingImage
        :pictures="setAttachments"
        :collection="item"
        :watch-for-pictures="false" />
    </component>
    <div class="description medium-div">
      <div
        class="mb-1 d-flex align-center"
        :data-test="`${testValue}_name`">
        <span>
          {{ item.title }}
        </span>
        <v-icon
          v-if="isShowActionIcon"
          medium
          color="mainGrey"
          class="brightness-icon ml-2"
          @click.stop="$emit('click-action-menu', {
            item,
            event: $event,
          })"
          v-on="$listeners">
          mdi-dots-vertical
        </v-icon>
      </div>
      <div
        v-if="showCollectionName"
        class="font-collection-name"
        :data-test="`${testValue}_collection_name`">
        Collection: {{ item.collectionName }}
      </div>
    </div>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  COLLECTIONS_CRITERIA,
  WORKSPACE_PAGES_CRITERIA,
} from '@/constants/searching/searchingAsyncV2';
import {
  ROUTE_COLLECTION_LIBRARY,
  ROUTE_COMMUNITY_COLLECTION,
  ROUTE_WORKSPACE_PAGE,
} from '@/constants';
import ListingImage from '@/components/Listing/ListingImageItem/ListingImage';
export default {
  name: 'CollectionsSearchItem',
  components: {
    ListingImage,
  },
  props: {
    criteria: {
      type: String,
      default: '',
    },
    isShowActionIcon: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    showCollectionName: {
      type: Boolean,
      default: false,
    },
    testValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('FeatureFlags', ['createAnchorForCollections']),
    computedRoute() {
      if (!this.createAnchorForCollections) return '';
      const { criteria = this.criteria } = this.item;
      if (criteria === COLLECTIONS_CRITERIA) {
        return {
          name: this.$route.meta.type == 'community' ? ROUTE_COMMUNITY_COLLECTION : ROUTE_COLLECTION_LIBRARY,
          params: {
            id: this.item.collection,
          },
        };
      } else if (criteria === WORKSPACE_PAGES_CRITERIA) {
        return {
          name: ROUTE_WORKSPACE_PAGE,
          params: {
            id: 'community',
            pageId: this.item.id,
          },
        };
      }
      return '';
    },
    setAttachments() {
      const { item } = this;
      const { attachment, logo: key } = item;
      let attachments = attachment.map(item => ({
        attachment: item,
      }));
      attachments = [
        ...attachments,
        ...(key ? [{
          key,
          isLogo: true,
        }] : []),
      ];
      return attachments;
    },
  },
};
</script>
<style scoped lang="scss">
.collection-search-item {
  .font-collection-name {
    font-size: 16px;
  }
  .medium-div {
    font-size: 16px;
    line-height: 19px;
  }
  .description {
    position: relative;
    word-break: break-all;
  }
}
</style>
